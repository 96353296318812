import apiEndpoints from '@/models/common/api-endpoints';
import witnessDateFilter from '@/components/WitnessDateFilter';
import witnessOptionSelector from '@/components/WitnessOptionSelector';
import tipsAndTricks from '@/components/TipsAndTricks';

const maxClinicsForLocationAndOperatorRequest = 10;

export default {
  name: 'WitnessFilter',
  components: {
    witnessDateFilter,
    witnessOptionSelector,
    tipsAndTricks
  },
  data: function () {
    return {
      startDate: undefined,
      endDate: undefined,
      
      // sub component control
      clinicSelectorDisabled: false,
      eventSelectorDisabled: true,
      locationSelectorDisabled: true,
      operatorSelectorDisabled: true,
    
      // sub component data
      clinicData: null,
      mapClinicIdToData: new Map(),
      mapClinicIdName: new Map(),
      clinicOptions: new Array(),
      selectedClinicOptionIds: new Array(),
      
      eventData: null,
      eventOptions: new Array(),
      selectedEventOptionIds: new Array(),
      
      locationsAndOperatorsData: null,
      mapClinicIdToLocationAndOperatorData: new Map(),
      locationOptions: new Array(),
      mapLocationOptionIdToClinicId: new Map(),
      selectedLocationOptionIds: new Array(),
      operatorOptions: new Array(),
      mapOperatorOptionIdToClinicId: new Map(),
      selectedOperatorOptionIds: new Array()
    };
  },
  mounted() {
    this.retrieveAllClinics();
    this.clinicSelectionChange();
    
    // NOTE: Might need to wait for Clinics to be loaded
    // this.initialise();
  },
  methods: {
    updateDateRange: function (startDate, endDate) {
      this.startDate = startDate;
      this.endDate = endDate;
      
      this.applyFilters();
    },

    requestDateJump: function (startDate, endDate) {
      this.updateDateRange(startDate, endDate);
    },

    getSelectedClinicGuids: function () {
      let selectedClinicGuids = [];
      
      if (this.selectedClinicOptionIds?.length > 0) {
        for (let ix = 0; ix < this.selectedClinicOptionIds.length; ix++) {
          let clinicGuid = this.clinicOptions[this.selectedClinicOptionIds[ix]].objId;
          selectedClinicGuids.push(clinicGuid);
        }
      }
      else {
        selectedClinicGuids = null;
      }
      
      return selectedClinicGuids;
    },

    getSelectedNonProcedureEvents: function () {
      let selectedNonProcedureEvents = {
        includeMismatches: false,
        includeDiscards: false,
        includeAssigns: false
      };

      for (let ix = 0; ix < this.selectedEventOptionIds.length; ix++) {
        const eventName = this.eventOptions[this.selectedEventOptionIds[ix]].label;
        if (eventName == 'Assign' ) {
          selectedNonProcedureEvents.includeAssigns = true;
        }
        if (eventName == 'Discard' ) {
          selectedNonProcedureEvents.includeDiscards = true;
        }
        if (eventName == 'Mismatch' ) {
          selectedNonProcedureEvents.includeMismatches = true;
        }

      }

      // Nothing selected means get EVERYTHING 
      if (this.selectedEventOptionIds.length == 0) {
        selectedNonProcedureEvents.includeMismatches = true;
        selectedNonProcedureEvents.includeDiscards = true;
        selectedNonProcedureEvents.includeAssigns = true;
      }
      
      return selectedNonProcedureEvents;
    },

    getSelectedEventNames: function () {
      let selectedEventNames = [];
      
      for (let ix = 0; ix < this.selectedEventOptionIds.length; ix++) {
        if (this.selectedEventOptionIds[ix] > 3) {
          // Ignore special case events of Assigns, Discards & Mismatches
          let eventName = this.eventOptions[this.selectedEventOptionIds[ix]].label;
          selectedEventNames.push(eventName);
        }
      }

      // Nothing selected means get EVERYTHING, and API expects null to do that 
      if (this.selectedEventOptionIds.length == 0) {
        selectedEventNames = null;
      }
      
      return selectedEventNames;
    },

    getSelectedLocationsByClinic: function () {
      let selectedLocations = [];
      
      if (this.selectedLocationOptionIds.length > 0) {
        for (let ix = 0; ix < this.selectedLocationOptionIds.length; ix++) {
          const locationObject = this.locationOptions[this.selectedLocationOptionIds[ix]];
          const locationName = locationObject.label;
          const clinicGuid = this.mapLocationOptionIdToClinicId.get(locationObject.id);
          selectedLocations.push({
            clinicid: clinicGuid,
            name: locationName
          });
        }
      }
      else {
        selectedLocations = null;
      }
      
      return selectedLocations;
    },

    getSelectedOperatorsByClinic: function () {
      let selectedOperators = [];
      
      if (this.selectedOperatorOptionIds.length > 0) {
        for (let ix = 0; ix < this.selectedOperatorOptionIds.length; ix++) {
          const operatorObject = this.operatorOptions[this.selectedOperatorOptionIds[ix]];
          const operatorName = operatorObject.label;
          const clinicGuid = this.mapOperatorOptionIdToClinicId.get(operatorObject.id);
          selectedOperators.push({
            clinicid: clinicGuid,
            name: operatorName
          });
        }
      }
      else {
        selectedOperators = null;
      }
      
      return selectedOperators;
    },
  
    initialise: function () {
      this.applyFilters();
    },
    applyFilters: function () {
      const selectedClinicGuids = this.getSelectedClinicGuids();
      const selectedNonProcedureEvents = this.getSelectedNonProcedureEvents();
      const selectedEventsNames = this.getSelectedEventNames();
      const selectedLocations = this.getSelectedLocationsByClinic();
      const selectedOperators = this.getSelectedOperatorsByClinic();
      this.$emit('applyFilters', selectedClinicGuids, selectedNonProcedureEvents, selectedEventsNames, selectedLocations, selectedOperators, this.startDate, this.endDate);
    },
    retrieveAllClinics: async function () {
      const response = await apiEndpoints.getAllClinics();

      this.selectedClinicOptionIds = [];
      
      if (response.result) {
        this.clinicData = response.data;
      } else {
        this.clinicData = null;
      }
    },
    retrieveAllEvents: async function () {
      let selectedClinicIds = Array();
      if (!this.selectedClinicOptionIds || this.selectedClinicOptionIds.length === 0) {
        selectedClinicIds = null;
      } else {
        for (let ix = 0; ix < this.selectedClinicOptionIds.length; ix++) {
          let selectedClinicOptionId = this.selectedClinicOptionIds[ix];
          let selectedClinicId = this.clinicOptions[selectedClinicOptionId].objId;
          selectedClinicIds.push(selectedClinicId);
        }
      }

      this.eventSelectorDisabled = true;
      const response = await apiEndpoints.getAllWitnessPointNames(selectedClinicIds);

      this.selectedEventOptionIds = [];
      
      if (response.result) {
        this.eventData = response.data;
        this.eventSelectorDisabled = false;
      } else {
        this.eventData = null;
        this.eventSelectorDisabled = true;
      }
    },
    retrieveAllLocationsAndOperators: async function () {
      let selectedClinicIds = Array();
      if (!this.selectedClinicOptionIds || this.selectedClinicOptionIds.length === 0) {
        selectedClinicIds = null;
      } else {
        for (let ix = 0; ix < this.selectedClinicOptionIds.length; ix++) {
          let selectedClinicOptionId = this.selectedClinicOptionIds[ix];
          let selectedClinicId = this.clinicOptions[selectedClinicOptionId].objId;
          selectedClinicIds.push(selectedClinicId);
        }
      }
      
      this.locationSelectorDisabled = true;
      this.operatorSelectorDisabled = true;
      const response = await apiEndpoints.getAllLocationsAndOperators(selectedClinicIds);
      this.selectedLocationOptionIds = [];
      this.selectedOperatorOptionIds = [];
      
      if (response.result) {
        this.locationsAndOperatorsData = response.data;
        this.locationSelectorDisabled = false;
        this.operatorSelectorDisabled = false;
      } else {
        this.locationsAndOperatorsData = null;
        this.locationSelectorDisabled = true;
        this.operatorSelectorDisabled = true;
      }
    },
    updateClinicSelector: function () {
      if (this.mapClinicIdToData != null)
      {
        // Build representation of options, inc groupings, in abstracted format expected by option-selector component
        let nextId = 0;
        
        for (const clinicData of this.mapClinicIdToData.values()) {
          let clinicOption = { id: nextId++, groupId: null, label: clinicData.clinicname, objId: clinicData.clinicid };
          
          this.clinicOptions.push(clinicOption);
        }        
      }
    },
    updateEventSelector: function () {
      while (this.eventOptions.length > 0) {
        this.eventOptions.pop();
      }
      
      if (this.eventData != null)
      {
        // Build representation of options, inc groupings, in abstracted format expected by option-selector component
        let nextId = 0;
        this.eventOptions.push({ id: nextId++, groupId: null, label: 'Pre-defined Events', objId: null });

        // - start with three "fixed" options for Non-Procedures
        this.eventOptions.push({ id: nextId++, groupId: null, label: 'Assign', objId: 0 });
        this.eventOptions.push({ id: nextId++, groupId: null, label: 'Discard', objId: 0 });
        this.eventOptions.push({ id: nextId++, groupId: null, label: 'Mismatch', objId: 0 });

        // - now add discovered Procedures
        let proceduresGroupId = nextId;
        this.eventOptions.push({ id: nextId++, groupId: null, label: 'Witness Points', objId: null });
        
        this.eventData.forEach(eventData => {
          let eventOption = { id: nextId++, groupId: proceduresGroupId, label: eventData, objId: 0 };
          
          this.eventOptions.push(eventOption);
        });
      }
    },
    updateLocationSelector: function () {
      while (this.locationOptions.length > 0) {
        this.locationOptions.pop();
      }
      this.mapLocationOptionIdToClinicId.clear();
      
      if (this.mapClinicIdToLocationAndOperatorData != null) {
        // Build representation of options, inc groupings, in abstracted format expected by option-selector component
        let nextId = 0;
        
        // - Process in clinic order
        for (const clinicId of this.mapClinicIdToData.keys()) {
          
          // - check if Clinic has Locations and Operators data
          if (this.mapClinicIdToLocationAndOperatorData.has(clinicId)) {
            
            // - add a group for the Clinic
            let clinicGroupId = nextId;
            let locationOption = { id: nextId++, groupId: null, label: this.mapClinicIdName.get(clinicId), objId: null };
            this.locationOptions.push(locationOption);
            
            // - add an option for each Location
            let clinicLocationAndOperatorInfo = this.mapClinicIdToLocationAndOperatorData.get(clinicId);
            if (clinicLocationAndOperatorInfo) {
              clinicLocationAndOperatorInfo.locations.forEach(location => {
                let locationOption = { id: nextId++, groupId: clinicGroupId, label: location, objId: 0 };
                this.locationOptions.push(locationOption);
                
                this.mapLocationOptionIdToClinicId.set(locationOption.id, clinicId);
              });
            }
          }
        }
      }
    },
    updateOperatorSelector: function () {
      while (this.operatorOptions.length > 0) {
        this.operatorOptions.pop();
      }
      this.mapOperatorOptionIdToClinicId.clear();
      
      if (this.mapClinicIdToLocationAndOperatorData != null) {
        // Build representation of options, inc groupings, in abstracted format expected by option-selector component
        let nextId = 0;
        
        // - Process in clinic order
        for (const clinicId of this.mapClinicIdToData.keys()) {
          
          // - check if Clinic has Locations and Operators data
          if (this.mapClinicIdToLocationAndOperatorData.has(clinicId)) {
            
            // - add a group for the Clinic
            let clinicGroupId = nextId;
            let operatorOption = { id: nextId++, groupId: null, label: this.mapClinicIdName.get(clinicId), objId: null };
            this.operatorOptions.push(operatorOption);
            
            // - add an option for each Location
            let clinicLocationAndOperatorInfo = this.mapClinicIdToLocationAndOperatorData.get(clinicId);
            if (clinicLocationAndOperatorInfo) {
              clinicLocationAndOperatorInfo.operators.forEach(operator => {
                let operatorOption = { id: nextId++, groupId: clinicGroupId, label: operator, objId: 0 };
                this.operatorOptions.push(operatorOption);
                
                this.mapOperatorOptionIdToClinicId.set(operatorOption.id, clinicId);
              });
            }
          }
        }
      }
    },
    clinicSelectionChange: function (data) {
      this.selectedClinicOptionIds = data;

      this.selectedEventOptionIds = [];
      this.selectedLocationOptionIds = [];
      this.selectedOperatorOptionIds = [];
      
      this.retrieveAllEvents();
      this.retrieveAllLocationsAndOperators();
      
      this.applyFilters();
    },
    eventSelectionChange: function (data) {
      this.selectedEventOptionIds = data;
      
      this.applyFilters();
    },
    locationSelectionChange: function (data) {
      this.selectedLocationOptionIds = data;
      
      this.applyFilters();
    },
    operatorSelectionChange: function (data) {
      this.selectedOperatorOptionIds = data;
      
      this.applyFilters();
    },
    compareClinicByName: function (clinicA, clinicB) {
      if (clinicA.clinicname.toLowerCase() < clinicB.clinicname.toLowerCase()) {
        return -1;
      }
      if (clinicA.clinicname.toLowerCase() > clinicB.clinicname.toLowerCase()) {
        return 1;
      }
      return 0;
    },
    updateClinicInfo: function () {
      this.mapClinicIdToData.clear();
      
      // clone instead of ref
      let tmp = JSON.parse(JSON.stringify(this.clinicData));
      if (tmp) {
        // sort by clinicName
        tmp.sort(this.compareClinicByName);
  
        // build map in that order
        tmp.forEach(t => {
          this.mapClinicIdToData.set(t.clinicid, t);
        });
      }
    },
    updateClinicMaps: function () {
      this.mapClinicIdName.clear();
      
      this.clinicData.forEach(clinicData => {
        this.mapClinicIdName.set(clinicData.clinicid, clinicData.clinicname);
      });
    },
    updateLocationAndOperatorInfo: function () {
      this.mapClinicIdToLocationAndOperatorData.clear();

      // update map of clinicId to LOData
      if (this.locationsAndOperatorsData) {
        this.locationsAndOperatorsData.forEach(t => {
          this.mapClinicIdToLocationAndOperatorData.set(t.clinicid, t);
        });
      }
    }
  },
  watch: {
    clinicData: function (newVal, oldVal) {
      this.updateClinicInfo();
      this.updateClinicSelector();
      this.updateClinicMaps();
    },
    eventData: function (newVal, oldVal) {
      this.updateEventSelector();
    },
    locationsAndOperatorsData: function (newVal, oldVal) {
      this.updateLocationAndOperatorInfo();
      this.updateLocationSelector();
      this.updateOperatorSelector();
    }
  }
};
