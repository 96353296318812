<template>
      <v-card tile outlined class="filter-component-container pa-0">

        <v-card-actions class="pa-0">
          <v-row class="px-0">
            <v-col cols=12 sm=6 md=3 lg=3>
              <witnessOptionSelector name="Clinics" 
                v-bind:disabled="clinicSelectorDisabled" 
                v-bind:options="clinicOptions"
                v-on:selection-updated="clinicSelectionChange">
              </witnessOptionSelector>
            </v-col>
            <v-col cols=12 sm=6 md=3 lg=3>
              <witnessOptionSelector name="Events" 
                v-bind:disabled="eventSelectorDisabled" 
                v-bind:options="eventOptions"
                v-on:selection-updated="eventSelectionChange">
                >
              </witnessOptionSelector>
            </v-col>
            <v-col cols=12 sm=6 md=3 lg=3>
              <witnessOptionSelector name="Locations" 
                v-bind:disabled="locationSelectorDisabled" 
                v-bind:options="locationOptions"
                v-on:selection-updated="locationSelectionChange">
              </witnessOptionSelector>
            </v-col>
            <v-col cols=12 sm=6 md=3 lg=3>
              <witnessOptionSelector name="Operators" 
                v-bind:disabled="operatorSelectorDisabled" 
                v-bind:options="operatorOptions"
                v-on:selection-updated="operatorSelectionChange">
              </witnessOptionSelector>
            </v-col>
            <v-col cols=12 sm=12 md=12 class="d-flex">
              <witnessDateFilter @updateDateRange="updateDateRange" @requestDateJump="requestDateJump">
              </witnessDateFilter>
              <tips-and-tricks class="ml-2 mt-0" goto="using_the_filters"/>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
</template>

<style scoped>

.filter-component-container {
  background-color: #FCFCFC;
  border: none;
}

</style>

<script>

import witnessFilterComponentModel from '@/component-models/witness-filter-component-model';

export default witnessFilterComponentModel;

</script>