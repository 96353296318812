<template>
  <div>
    <v-dialog
      v-model="help_dialog_open"
      :transition="false"
      scrollable
      width="80%"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="primary"
          icon
          v-bind="attrs"
          v-on="on"
        >
          <v-icon size=32 color="#F58025">$riwIconSet_help</v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-card-title class="ma-0 pa-4">
          <v-icon color="primary" size=26 class="ml-2 mr-2">$riwIconSet_help</v-icon>
          <h1 class="ma-0 pa-0" style="font-weight: 500;">Help</h1>
        </v-card-title>
        <v-divider />
        <v-card-text ref="help_content_card" id="help_content_card">
          <div class="mx-4">
            <h2 class="help_heading mt-10" id="using_the_dashboard">
              Using the Dashboard
            </h2>
            <div class="mx-4 my-4">
              <v-img
                
                max-height="50vh"
                contain
                @load="gotoProp"
                eager
                lazy-src="help_using_dashboard_lazy.jpg"
                src="help_using_dashboard.gif"
              >
              </v-img>
            </div>
            <p class="mx-4 mt-8">
            With the 'Edit' option enabled you can:
            <ul>
              <li>Move/reorder items by clicking and dragging</li>
              <li>Rename an item by clicking the item's rename icon ( <v-icon x-small>$riwIconSet_edit</v-icon> )</li>
              <li>Remove an item from the dashboard by clicking the item's remove icon ( <v-icon x-small>$riwIconSet_delete</v-icon> )</li>
            </ul>
            </p>
            <p class="mx-4">
              The 'Show Description' option will display information about the configuration of each item on the dashboard.
            </p>
            <h2 class="help_heading mt-10" id="adding_to_the_dashboard">
              Adding an Item to the Dashboard
            </h2>
            <div class="mx-4 my-4">
              <v-img
                max-height="50vh"
                contain
                @load="gotoProp"
                eager
                lazy-src="add_to_dashboard_lazy.jpg"
                src="add_to_dashboard.gif"
              >
              </v-img>
            </div>
            <p class="mx-4 mt-8">
              On the events page (and on the intervals page when a saved interval is being viewed), a summary item is shown like the one in the image above. Clicking on the 'add' icon ( <v-icon x-small>$riwIconSet_addToDashboard</v-icon> ) will add the summary to the dashboard.
            </p>
            <h2 class="help_heading mt-10" id="using_the_filters">
              Using the Filters
            </h2>
            <div class="mx-4 my-4">
              <v-img
                max-height="50vh"
                contain
                @load="gotoProp"
                eager
                lazy-src="filters_lazy.jpg"
                src="filters.gif"
              >
              </v-img>
            </div>
            <p class="mx-4 mt-8">
              At the top of most pages there is a 'filter' section; this consists of several selection boxes which can be used to filter the data shown, and a date selector which allows for the date range and period to be customised and stepped through.
            </p>
            <p class="mx-4 mt-8">
              In their default state (nothing selected), the selection boxes will allow all available data to be shown for the selected period. When one or more selections are made, only the data which fits the filter criteria are shown.
            </p>
            <h2 class="help_heading mt-10" id="setting_up_an_interval">
              Setting Up an Interval
            </h2>
            <div class="mx-4 my-4">
              <v-img
                max-height="50vh"
                contain
                @load="gotoProp"
                eager
                lazy-src="intervals_lazy.jpg"
                src="intervals.gif"
              >
              </v-img>
            </div>
            <p class="mx-4 mt-8">
              If there are no intervals configured for the selected clinic, or the 'Explore Intervals' button is pressed, then the configuration settings for an interval will be shown (as demonstrated above).
            </p>
            <p class="mx-4 mt-8">
              Once an interval has been configured, the results can be seen immediately. Those with admin permissions for a clinic may save an interval for future use; these saved intervals will be available to all users of RI Witness IQ who have visibility of the selected clinic. Admin users will also have access to other management options when configuring existing intervals (Rename, Delete, Save, Save as New).
            </p>
          </div>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="blue darken-1"
            text
            @click="$vuetify.goTo(0, { container: $refs.help_content_card})"
          >
            Back to Top
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="help_dialog_open = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<style scoped>
/* help_heading {

}
help_subheading {

}
help_sub_subheading {

} */
.v-card__subtitle, .v-card__text p {
  font-size: 18px !important;
}
</style>

<script>
import tipsAndTricksComponentModel from '@/component-models/tips-and-tricks-component-model';
export default tipsAndTricksComponentModel;
</script>