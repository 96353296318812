<template>
  <v-menu
    v-model="popupIsOpen"
    :disabled="disabled"
    :close-on-content-click="false"
  >
    <template v-slot:activator="{ on }">

      <v-btn
        x-large
        text
        v-on="on"
        class="dropdownButton"
        :disabled="disabled"
      >

        {{ selected }}

        <v-icon right class="btnIcon">
          $riwIconSet_expandDown
        </v-icon>

        <div class="btbLabel">{{ name }}</div>
        
      </v-btn>

    </template>

    <div class="dropdownPopup">

      <div class="btbLabelPopup">{{ name }}</div>

      <div class="dropdownPopupFilter">
        <v-text-field v-bind:append-icon="filterIcon" placeholder="Search" hide-details v-model="filter" @click:append="filterIconClick" class="dropdownSearchBox">
        </v-text-field>
        <v-icon @click="popupIsOpen = false" class="dropdownCollapseUp">$riwIconSet_collapseUp</v-icon>
      </div>

      <div class="dropdownPopupList">
        <v-radio-group v-if="singleSelectionMode" v-model="singleSelectionId">
          <div v-for="id in arrIds" :key="id">
            <div v-if="arrVisibles[id] == true">
              <div v-if="arrObjIds[id] == null" class="dropdownPopupListGroup" >{{ arrLabels[id] }}</div>
              <div v-else class="dropdownPopupListOption">
                <v-radio :label="arrLabels[id]" :value="id" />
              </div>
            </div>
          </div>
        </v-radio-group>
        <div v-else>
          <div v-for="id in arrIds" :key="id">
            <div v-if="arrVisibles[id] == true">
              <div v-if="arrObjIds[id] == null" class="dropdownPopupListGroup" >{{ arrLabels[id] }}</div>
              <div v-else class="dropdownPopupListOption">
                <v-checkbox :label="arrLabels[id]" v-model="arrSelecteds[id]" :dense="true" hide-details />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="dropdownPopupFooter">
        <div class="dropdownPopupIcons">
          Clear selection&nbsp;&nbsp;<v-icon class="pa-0 dropdownClearButton" @click="resetAndClosePopup">$riwIconSet_close</v-icon>
        </div>
      </div>

    </div>

  </v-menu>
</template>
  
<style scoped>
.v-btn.v-size--x-large {
  font-size: 12px;
  letter-spacing: 1px;
}
.v-menu__content {
  overflow-y: visible!important;
  overflow-x: visible!important;
  contain: none;
}
.v-btn:hover:before {
  background-color: transparent;
  display: none;
}
.dropdownButton {
  width: 100%;
  justify-content: left;
  text-transform: none;
  font-size: 16px!important;
  font-weight: 300;
  border: 1px solid #005CAB;
  border-radius: 4px;
  background-color: #FCFCFC;
}
.btnIcon {
  position: absolute;
  right: 0px;
}
.btbLabel {
  position: absolute;
  left: -10px;
  top: -17px;
  background-color: #FCFCFC;
  padding-left: 5px;
  padding-right: 5px;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #005CAB;
  z-index: 7;
}
.btbLabelPopup {
  position: absolute;
  left: 15px;
  top: -8px;
  background-color: #FCFCFC;
  padding-left: 5px;
  padding-right: 5px;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #005CAB;
  z-index: 15;
}
.dropdownPopup {
  width: 100%;
  border: 1px solid #005CAB;
  border-radius: 4px;
  background-color: #FCFCFC;
  opacity: 1.0;
  padding-top: 0px;
  padding-right: 23px;
  padding-bottom: 12px;
  padding-left: 23px;
}
.dropdownPopupTitle {
  float: left;
  font-size: 16px;
  font-weight: 300;
}
.dropdownSearchBox {
  width: 85%;
  float: left;
  font-size: 16px;
}
.dropdownCollapseUp {
  position: absolute;
  top: 14px;
  right: 24px;
  font-size: 18px;
}
.dropdownPopupIcons {
  text-align: right;
  padding-top: 23px;
  font-size: 12px;
}
.dropdownPopupFilter {
  clear: both;
  margin-bottom: 4px;
}
.dropdownPopupList {
  height: 300px;
  overflow-y: auto;
  clear: both;
}
.dropdownPopupListGroup {
  margin-top: 4px;
  font-weight: bold;
}
.dropdownPopupListOption {
  padding-left: 4px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.dropdownPopupListOption:hover {
  background-color: #F2F2F2;
}
.dropdownClearButton {
  font-size: 20px;
  font-weight: 300;
  color: #E1134F!important;
}
</style>

<script>

import witnessOptionSelectorComponentModel from '@/component-models/witness-option-selector-component-model';

export default witnessOptionSelectorComponentModel;

</script>
