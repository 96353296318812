/**
 * OptionSelector component
 * 
 * Notes on the options representation
 * -----------------------------------
 * 
 * This is an abstracted representation of options and groups (labels) for a set of options
 * 
 * It is the responsibilty of the parent component to construct this representation from
 * actual data retrieved via the APIs or whatever.
 * 
 * Each Option has 4 fields
 *  + id        - the index of the option/group in the data provided
 *  + groupId   - the id (index) of the option that this option is to be grouped under
 *  + label     - the text to be displayed for this option
 *  + objId     - the actual underlying object's id, where appropriate.
 * 
 * This representation is used in the following way
 *  + When rendering options, if an option has a null objId then it is displayed as a 
 *    group label, not a selectable option. For options that don't have an underlying
 *    object the parent component is recommended to just use 0.
 *  + When filtering options, if an option is selected then the groupId is used to look
 *    up the option for the group label and make sure that is displayed too
 * 
 * Notes on the arrXYZ representation
 * ----------------------------------
 * 
 * The component needs a representation of what is selected and, when filtering, what is
 * still visible. Unfortunately, it was not possible to extend the options provided with
 * this internal data because Vue.JS does not react to changing the properties on an
 * object within an array. 
 * 
 * In fact, Vue.JS is rather limited in the data structures in can react to.
 * 
 * The Vue.JS friendly representation we found was to use a simple array per property. 
 * This allowed the use of the this.$set() function to update an individual property in
 * a way that Vue.JS will notice.
 * 
 * So, we ended up with these arrays
 *  + arrIds        - the option ids (indexes)
 *  + arrGroupIds   - the option groupIds
 *  + arrObjIds     - the option objIds
 *  + arrLabels     - the option labels
 *  + arrSelecteds  - whether the option has been selected
 *  + arrVisibles   - whether the option is currently visible
 * 
 * These arrays are indexed by the id of the option.
 * 
 * All these arrays are used to render the component.
 */
export default {
  name: 'WitnessOptionSelector',
  props: {
    name: String,
    options: Array,
    preselectedOptionIds: Array,
    disabled: Boolean,
    zeroSelectionLabel: {
      type: String,
      default: 'All'
    },
    singleSelectionMode: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    selected: function () {
      let selectedInfo = '';
      let selectedLabel = '';
      let count = 0;
      
      if (null != this.arrSelecteds)
      {
        for (let ix = 0; ix < this.arrSelecteds.length; ix++) {
          if (this.arrSelecteds[ix] == true) {
            count++;
            selectedLabel = this.arrLabels[ix];
          }
        }
      }
      
      if (count == 0) {
        selectedInfo = this.zeroSelectionLabel;
      }
      else if (count == 1) {
        selectedInfo = selectedLabel;
      }
      else {
        selectedInfo = count + ' ' + this.name;
      }
      
      return selectedInfo;
    }
  },
  data() {
    return {
      popupIsOpen: false,
      arrIds: null,
      arrGroupIds: null,
      arrObjIds: null,
      arrLabels: null,
      arrSelecteds: null,
      singleSelectionId: null,    // NOTE: can be null to allow no selection
      arrVisibles: null,
      filter: '',
      filterIcon: '$riwIconSet_search'
    };
  },
  mounted() {
    this.arrIds = new Array();
    this.arrGroupIds = new Array();
    this.arrObjIds = new Array();
    this.arrLabels = new Array();
    this.arrSelecteds = new Array();
    this.arrVisibles = new Array();
    this.buildInfo();
  },
  watch: {
    options: {
      handler: function (newVal, oldVal) {
        this.buildInfo();
      },
      deep: true
    },
    preselectedOptionIds: {
      handler: function (newVal, oldVal) {
        this.selectOptions(this.preselectedOptionIds);
      }
    },
    filter: {
      handler: function (newVal, oldVal) {
        this.updateFilterIcon();
        this.filterList();
      }
    },
    singleSelectionId: {
      handler: function (newVal, oldVal) {
        this.$set(this.arrSelecteds, oldVal, false);
        this.$set(this.arrSelecteds, newVal, true);
      }
    },
    popupIsOpen: {
      handler: function (newVal, oldVal) {
        if (newVal == false && oldVal == true) {
          this.emitSelectionUpdated();
          this.filter = '';
        }
      }
    }
    
  },
  methods: {
    //
    // Internal
    //
    selectOptions(optionIds) {
      // console.log('WOS-CM: selectOptions', optionIds);
      
      // Validate request - fail silently
      if (this.singleSelectionMode && optionIds.length > 1) {
        return;
      }
      
      // Clear current selection in single selection mode
      if (this.singleSelectionMode) {
        for (let i = 0; i < this.options.length; i++) {
          let v = this.options[i];
          this.arrSelecteds[v.id] = false;
        }
      }
      
      // Select those options requested
      for (let j = 0; j < optionIds.length; j++) {
        this.$set(this.arrSelecteds, optionIds[j], true);
      }
      if (this.singleSelectionMode) {
        this.singleSelectionId = optionIds[0];
      }
      
      // console.log('WOS-CM: selectOptions', this.arrSelecteds, this.singleSelectionId);
      
      // And similulate closing popup to send selection
      this.emitSelectionUpdated();
    },
    buildInfo() {
      // console.log('WOS-CM: buildInfo');
      
      // Reset
      this.arrIds = [];
      this.arrGroupIds = [];
      this.arrObjIds = [];
      this.arrLabels = [];
      this.arrSelecteds = [];
      this.arrVisibles = [];
      
      // Split data into arrays as we can just about get Vue to update when changed
      for (let i = 0; i < this.options.length; i++) {
        let v = this.options[i];
        
        this.arrIds[v.id] = v.id;
        this.arrGroupIds[v.id] = v.groupId;
        this.arrObjIds[v.id] = v.objId;
        this.arrLabels[v.id] = v.label;
        
        this.arrSelecteds[v.id] = false;
        this.arrVisibles[v.id] = true;
      }
    },
    filterIconClick() {
      if (this.filter.length > 0) {
        this.filter = '';
      }
    },
    updateFilterIcon() {
      if (this.filter.length > 0) {
        this.filterIcon = '$riwIconSet_close';
      }
      else {
        this.filterIcon = '$riwIconSet_search';
      }
    },
    resetAndClosePopup() {
      for (let ix = 0; ix < this.arrIds.length; ix++) {
        this.$set(this.arrSelecteds, ix, false);
      }
      this.singleSelectionId = null;
      
      this.popupIsOpen = false;
    },
    filterList() {
      if (this.filter.length > 0)
      {
        for (let ix = 0; ix < this.arrIds.length; ix++) {
          if (this.arrObjIds[ix] == null) {
            // Group so hide
            this.arrVisibles[ix] = false;
          }
          else if (this.arrLabels[ix].toLowerCase().includes(this.filter.toLowerCase())) {
            this.arrVisibles[ix] = true;
            
            // Now show group if member found
            if (this.arrGroupIds[ix] != null) {
              this.arrVisibles[this.arrGroupIds[ix]] = true;
            }
          }
          else {
            this.arrVisibles[ix] = false;
          }
        }
      }
      else {
        for (let ix = 0; ix < this.arrIds.length; ix++) {
          this.arrVisibles[ix] = true;
        }
      }
    },
    emitSelectionUpdated() {
      let selectedOptionIds = Array();
      
      for (let ix = 0; ix < this.arrSelecteds.length; ix++) {
        if (this.arrSelecteds[ix]) {
          selectedOptionIds.push(ix);
        }
      }
          
      this.$emit('selection-updated', selectedOptionIds);
    }
  }
  
};

