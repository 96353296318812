<template>

    <v-menu
      v-model="menu"
      :close-on-content-click="false"
    >
      <template v-slot:activator="{ on, attrs }">
        <div class="dateWrapper d-flex">
          <v-btn
            id="btn-date-filter"
            x-large
            text
            v-bind="attrs"
            v-on="on"
            class="dropdownButton"
          >
            <span class="selectedDateString">
              <template v-if="dateRangeTitle">
                {{ dateRangeTitle }}&nbsp;:
              </template>

              <template>{{ dateRangeString }}</template>
            </span>

            <v-icon right class="btnIcon">
              $riwIconSet_expandDown
            </v-icon>

            <div class="btbLabel">Dates</div>
          </v-btn>
          <div class="jumpWrapper" v-show="jumpAmount">
            <v-btn id="jump-prev" class="ml-4 secondary" @click="jumpBackward" color="secondary">
              <v-icon class="mr-2">
                $riwIconSet_jumpLeft
              </v-icon>
              Jump -{{ jumpAmount }} {{ displayedJumpPeriod }}
            </v-btn>
            <v-btn id="jump-next" class="ml-4 secondary" :disabled="!jumpForwardPossible" @click="jumpForward" color="secondary">
              Jump +{{ jumpAmount }} {{ displayedJumpPeriod }}
              <v-icon class="ml-2">
                $riwIconSet_jumpRight
              </v-icon>
            </v-btn>
          </div>
        </div>
      </template>

      <v-card class="dropdownPopup">

      <div class="btbLabelPopup">Dates</div>

        <div class="mx-3 pb-3">
          <v-row>
            <v-col class="mt-3 text-right">
              <v-btn id="date-filter-close" icon @click="minimiseDateFilter">
                <v-icon class="dropdownCollapseUp">
                  $riwIconSet_collapseUp
                </v-icon>
              </v-btn>
              <!-- <v-btn icon @click="clearAndCloseDateFilter">
                <v-icon>
                  $riwIconSet_close
                </v-icon>
              </v-btn> -->
            </v-col>
          </v-row>
          <v-radio-group class="mt-0" v-model="dateRadioGroup">
            <v-row class="ma-0">
              <v-col class="py-0" cols=12 sm=6 md=3>
                <v-radio
                  label="Today"
                  value="today"
                ></v-radio>
              </v-col>
            </v-row>
            <v-row class="ma-0">
              <template v-for="dateRangeGroup in dateRangeGroups">
                <v-col :key="dateRangeGroup.label" cols=12 sm=6 lg=3>
                  <h4 class="mb-2">{{ dateRangeGroup.label }}</h4>
                  <template v-for="dateRange in dateRangeGroup.specificDateRanges">
                    <div :key="dateRange">
                      <v-radio
                        :id="datesRanges[dateRange].id"
                        class="mb-2 text-no-wrap"
                        :label="datesRanges[dateRange].label"
                        :value="dateRange"
                      ></v-radio>
                    </div>
                  </template>
                </v-col>
              </template>
            </v-row>
            <v-divider class="mt-4 mb-2"></v-divider>
            <v-row class="ma-0">
              <v-col cols=6 sm=3 md=2>
                <v-radio class="mb-0"
                  label="Custom"
                  value="custom"
                ></v-radio>
              </v-col>
              <v-col cols=12 sm=10 md=8 lg=6 xl=4 v-if="dateRadioGroup == 'custom'">
                <v-row>
                  <v-text-field
                    class="mr-2"
                    outlined
                    readonly
                    label='from date'
                    v-model="formattedFromDate"
                  ></v-text-field>
                  <v-text-field
                    class="ml-2"
                    outlined
                    readonly
                    label='to date'
                    v-model="formattedToDate"
                  ></v-text-field>
                </v-row>
                <v-row>
                  <v-date-picker
                    v-model="datePickerArray"
                    range
                    full-width
                    show-adjacent-months
                    no-title
                    first-day-of-week=1
                    :min="minimumDatePickerValue"
                    :max="todayString"
                  >
                  </v-date-picker>
                </v-row>
              </v-col>
              <v-col>
                <v-btn
                  v-if="dateRadioGroup == 'custom'"
                  :disabled="datePickerArray.length == 0"
                  @click="clearDates"
                >
                  Clear
                </v-btn>
              </v-col>
            </v-row>
          </v-radio-group>
        </div>
      </v-card>
    </v-menu>

</template>

<style scoped>
.v-btn.v-size--x-large {
  font-size: 12px;
  letter-spacing: 1px;
}
.v-menu__content {
  overflow-y: visible!important;
  overflow-x: visible!important;
  contain: none;
}
.dropdownButton {
  width: 100%;
  justify-content: left;
  text-transform: none;
  font-size: 16px!important;
  font-weight: 300;
  border: 1px solid #005CAB;
  border-radius: 4px;
  background-color: #FCFCFC;
}
.btnIcon {
  position: absolute;
  right: 0px;
}
.btbLabel {
  position: absolute;
  left: -5px;
  top: -16px;
  background-color: #FCFCFC;
  padding-left: 5px;
  padding-right: 5px;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #005CAB;
  z-index: 7;
}
.btbLabelPopup {
  position: absolute;
  left: 15px;
  top: -12px;
  background-color: #FCFCFC;
  padding-left: 5px;
  padding-right: 5px;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #005CAB;
  z-index: 15;
}
.selectedDateString {
  max-width: 90%;
  overflow: hidden;
}
.dropdownPopup {
  width: 100%;
  border: 1px solid #005CAB;
  border-radius: 4px;
  background-color: #FCFCFC;
  opacity: 1.0;
  padding-top: 0px;
  padding-right: 23px;
  padding-bottom: 12px;
  padding-left: 23px;
}
.dropdownCollapseUp {
  position: absolute;
  top: -17px;
  right: -20px;
  font-size: 20px!important;
  color: #222222!important;
}
</style>

<script>

import witnessDateFilterComponentModel from '@/component-models/witness-date-filter-component-model';

export default witnessDateFilterComponentModel;

</script>