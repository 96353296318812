import dayjs from 'dayjs';
const utc = require('dayjs/plugin/utc');
const duration = require('dayjs/plugin/duration');
dayjs.extend(utc);
dayjs.extend(duration);

export default {

  calculateMonthStartDate: function (selectedDate) {
    var startDate = dayjs(selectedDate, 'YYYY-MM').startOf('month').format('YYYY-MM-DD');
    return startDate;
  },

  calculateMonthEndDate: function (selectedDate) {
    const endDate = dayjs(selectedDate, 'YYYY-MM').endOf('month').format('YYYY-MM-DD');
    return endDate;
  },

  deconstructDateTimeSeconds: function (dateTimeSeconds, showDays=false) {

    if (dateTimeSeconds === undefined) {
      return '';
    }
    
    const constructedDateTime = dayjs.duration(dateTimeSeconds, 'seconds');
    let deconstructedDateTime = '';

    // console.log('---------------------');
    // console.log(constructedDateTime.format('D[d:]HH:mm:ss'));
    // console.log(constructedDateTime.asDays().toString().split('.')[0]);
    // console.log(constructedDateTime.hours().toFixed(0));
    // console.log(constructedDateTime.minutes().toFixed(0));

    if (constructedDateTime.asDays().toString().split('.')[0] !== '0') {
      if(showDays){
        deconstructedDateTime = constructedDateTime.asDays().toString().split('.')[0] + constructedDateTime.format('[d:]HH:mm:ss');
      } else {
        deconstructedDateTime = constructedDateTime.asHours().toString().split('.')[0] + constructedDateTime.format(':mm:ss');
      }
      
    } else if (constructedDateTime.asHours().toString().split('.')[0] !== '0') {
      deconstructedDateTime = constructedDateTime.format('HH:mm:ss');
    } else if (constructedDateTime.asMinutes().toString().split('.')[0] !== '0') {
      deconstructedDateTime = constructedDateTime.format('mm:ss');
    } else {
      deconstructedDateTime = constructedDateTime.format('ss[s]');
    }

    // console.log(deconstructedDateTime);

    return deconstructedDateTime;
  },

  today: function () {
    return dayjs.utc().format('YYYY-MM-DD');
  },

  formatDateRangeString: function (startDate, endDate) {
    var formattedDateRange;
    const startDateFormatted = dayjs(startDate, 'YYYY-MM-DD');
    const endDateFormatted = dayjs(endDate, 'YYYY-MM-DD');

    if (startDate == endDate) {
      if (startDate == this.today()) {
        formattedDateRange = 'Today';
      } else {
        formattedDateRange = startDateFormatted.format('ddd D MMM YYYY');
      }
    } else if (endDate == this.today()) {
      formattedDateRange = startDateFormatted.format('ddd D MMM YYYY') + ' - Today';
    } else if (startDateFormatted.year() != endDateFormatted.year()) {
      formattedDateRange = startDateFormatted.format('ddd D MMM YYYY') + ' - ' + endDateFormatted.format('ddd D MMM YYYY');
    } else if (startDateFormatted.month() != endDateFormatted.month()) {
      formattedDateRange = startDateFormatted.format('ddd D MMM') + ' - ' + endDateFormatted.format('ddd D MMM YYYY');
    } else {
      formattedDateRange = startDateFormatted.format('ddd D') + ' - ' + endDateFormatted.format('ddd D MMM YYYY');
    }

    return formattedDateRange;
  }
};